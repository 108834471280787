<template>
  <b-card>
    <b-row>
      <b-col class="text-right">
        <b-button
          v-b-modal.carriers-modal
          variant="outline-primary"
          class="m-1"
          @click="setNewCarrier"
        >
          Add carrier
        </b-button>
      </b-col>
    </b-row>
    <carriers-modal
      id="carriers-modal"
      :carrier="selectedCarrier"
      :event-hub="eventHub"
      :company-list="companiesList"
    />
    <b-row>
      <b-col>
        <div class="overflow-x-scroll">
          <b-table
            id="carriers-table"
            :items="carriers"
            responsive
            :fields="tableFields"
            :busy="loading"
            stacked="sm"
            class="mb-0"
            show-empty
          >
            <template #table-busy>
              <div class="text-center my-2">
                <b-spinner class="align-middle mr-1" />
                <strong>Loading...</strong>
              </div>
            </template>

            <template
              v-if="companiesList.length > 1"
              #cell(logo)="data"
            >
              <CompanyThumb
                :logo="data.item.company.logo"
                :name="data.item.company.name"
              />
            </template>

            <template
              #cell(actions)="data"
              class="px-1"
            >
              <b-button-group>
                <b-button
                  v-if="$permissions().hasPermission('edit carrier')"
                  variant="primary"
                  size="sm"
                  @click="editCarrier(data.item.id)"
                >
                  <feather-icon
                    icon="EditIcon"
                    class="d-inline"
                  />
                </b-button>
                <b-button
                  v-if="$permissions().hasPermission('delete carrier')"
                  variant="outline-danger"
                  size="sm"
                  @click="deleteCarrier(data.item)"
                >
                  <feather-icon
                    v-if="deletingId !== data.item.id"
                    icon="TrashIcon"
                    class="d-inline"
                  />
                  <b-spinner
                    v-else
                    small
                  />
                </b-button>
              </b-button-group>
            </template>
          </b-table>
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BTable,
  BSpinner,
  BCard,
  BButtonGroup,
} from 'bootstrap-vue'
import Vue from 'vue'
import CarriersModal from './CarriersModal.vue'
import handleError from '@/views/components/errorHandler'
import CompanyThumb from '@/views/components/CompanyThumb.vue'

export default {
  components: {
    CompanyThumb,
    BButtonGroup,
    BSpinner,
    BCard,
    BTable,
    BRow,
    BCol,
    BButton,
    CarriersModal,
  },
  data() {
    return {
      carriers: [],
      selectedCarrier: [],
      loading: false,
      eventHub: new Vue(),
      userCompanies: [],
      deletingId: null,
    }
  },
  computed: {
    companiesList() {
      return this.$data.userCompanies
    },
    tableFields() {
      const tableColumns = [
        ...[this.companiesList.length > 1 ? { key: 'logo', label: 'Company', sortable: false } : null],
        { key: 'name', label: 'Name' },
        { key: 'code', label: 'Code' },
        { key: 'type', label: 'Type' },
        { key: 'transport_document_type', label: 'Transport Doc. Type' },
        { key: 'eori_number', label: 'EORI' },
      ]
      if (
        this.$permissions().hasPermission('edit company')
        || this.$permissions().hasPermission('delete company')
      ) {
        tableColumns.push({ key: 'actions', label: 'Actions' })
      }
      return tableColumns
    },
  },
  mounted() {
    this.$data.userCompanies = this.$profile().data.user.companies
    this.getCarriers()
    this.eventHub.$on('refreshCarriers', this.getCarriers)
  },
  beforeDestroy() {
    this.eventHub.$off('refreshCarriers', this.getCarriers)
  },
  methods: {
    getCarriers() {
      this.loading = true

      this.$http.get(`/v1/companies/${this.$activeCompany().data.company.id}/carriers`)
        .then(response => {
          this.carriers = response.data.data
        })
        .catch(error => {
          handleError(error, this.$toast)
        })
        .finally(() => {
          this.loading = false
        })
    },
    editCarrier(id) {
      const selectedCarrier = this.carriers.find(carrier => carrier.id === id)
      this.selectedCarrier = {
        id: selectedCarrier.id,
        name: selectedCarrier.name,
        code: selectedCarrier.code,
        type: selectedCarrier.type,
        address: selectedCarrier.address,
        transport_document_type: selectedCarrier.transport_document_type,
        eori_number: selectedCarrier.eori_number,
        company_id: selectedCarrier.company.id,
      }
      this.$bvModal.show('carriers-modal')
    },
    deleteCarrier(carrier) {
      this.deletingId = carrier.id

      this.$http.delete(`/v1/companies/${carrier.company.id}/carriers/${carrier.id}`)
        .then(() => {
          const index = this.carriers.findIndex(carrierItem => carrierItem.id === this.deletingId)
          this.carriers.splice(index, 1)
        })
        .catch(error => {
          handleError(error, this.$toast)
        })
        .finally(() => {
          this.deletingId = null
        })
    },
    setNewCarrier() {
      this.selectedCarrier = {
        id: null,
        name: '',
        code: '',
        type: '',
        address: '',
        eori_number: '',
        transport_document_type: '',
        company_id: null,
      }
    },
  },
}
</script>

<style lang="scss">
</style>
